@use '@angular/material' as mat;
@use '@hop/styles/var';
@use '@hop/styles/ckeditor';
@use '@hop/styles/ckeditor-styles';
//@use 'node_modules/utopia-core-scss/src/utopia' as utopia;
//@import '~simplebar/dist/simplebar.css';
//@import '~highlight.js/styles/androidstudio.css';
// Hop Core
@import './@hop/styles/core';
@import 'ngx-toastr/toastr';


//@import "primeng/resources/primeng.css";
//@import "primeng/resources/themes/bootstrap4-light-blue/theme.css";
//@import "primeng/resources/components/badge/badge.css";
//@import "primeng/resources/components/accordion/accordion.css";
//@import "primeng/resources/components/avatar/avatar.css";
.p-accordion {
  & .p-accordion-header {
    & .p-accordion-header-link {
      @apply flex overflow-hidden bg-foreground font-semibold rounded-md transition-all duration-200;
      @apply bg-foreground flex m-auto rounded-lg border w-full;

      & .p-accordion-toggle-icon {
        @apply absolute mr-2 right-0;
      }
    }

    &:not(.p-disabled) {
      & .p-accordion-header-link:focus-visible {
        //@apply outline-none shadow-none;
        //box-shadow: inset 0 0 0 0.2rem #BFDBFE;
        @apply border;
      }
    }

    &:not(.p-highlight):not(.p-disabled):hover {
      & .p-accordion-header-link {
        @apply bg-gray-200 dark:bg-gray-700;
      }
    }

    &.p-highlight:not(.p-disabled) {
      & .p-accordion-header-link {
        @apply bg-gray-100 dark:bg-gray-600 border-gray-300 rounded-b-none;
      }

      &:hover {
        & .p-accordion-header-link {
          @apply bg-gray-200 dark:bg-gray-700 border-gray-300;
        }
      }
    }
  }

  & .p-accordion-content {
    @apply bg-foreground px-5 py-5 border-t-0 rounded-t-none rounded-b-md border;
  }

  & p-accordiontab {
    & .p-accordion-tab {
      @apply mb-8;
    }
  }
}


//@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=PT+Serif:ital,wght@0,400;0,700;1,400&display=swap');

/*
  You can override any CSS Variable or style here
  Example:

  :root {
   --sidenav-background: #333333;
  }

  All possible variables can be found in @hop/styles/core or by simply inspecting the element you want to change
  in Chrome/Firefox DevTools
*/

/**
  Uncomment the below code and adjust the values to fit your colors, the application will automatically adjust
 */
:root {
  // --color-primary: blue;
  // --color-primary-contrast: white;

  // --color-accent: yellow;
  // --color-accent-contrast: black;

  // --color-warn: yellow;
  // --color-warn-contrast: black;

  .snackbar-primary {
    .mdc-snackbar__surface {
      background: rgb(var(--color-primary));

      .mat-mdc-snack-bar-label {
        color: rgb(var(--color-primary-contrast));
      }
    }
  }

  .snackbar-warn {
    .mdc-snackbar__surface {
      background: rgba(var(--color-warn));

      .mat-mdc-snack-bar-label {
        color: rgb(var(--color-warn-contrast));
      }
    }
  }

  .snackbar-accent {
    .mdc-snackbar__surface {
      .mat-mdc-snack-bar-label {
        color: rgb(var(--color-accent-contrast));
      }

      background: rgb(var(--color-accent));
    }
  }



  /*.dark {
    .snackbar-primary {
      .mdc-snackbar__surface {
        background: rgb(var(--color-primary-contrast));
      }
    }

    .snackbar-warn {
      .mdc-snackbar__surface {
        background: rgba(var(--color-warn-contrast));
        color: whitesmoke;
      }
    }

    .accent-snackbar {
      .mdc-snackbar__surface {
        background: rgb(var(--color-accent-contrast));
        color: whitesmoke;
      }
    }
  }*/

  .custom-modalbox-hop-calendar {
    max-width: 100% !important;
    max-height: 100% !important;
    width: 90%;
    height: 80%;
  }

  @media (min-width: 750px) {
    .custom-modalbox-hop-calendar {
      width: 57%;
      height: 80%;
    }
  }

  .toast-success {
    background-color: #21a221;
  }

  .toast-error {
    background-color: rgb(var(--color-warn));
  }

  .toast-info {
    background-color: rgb(var(--color-primary));
  }

  .toast-warning {
    background-color: rgb(var(--color-accent));
  }

  .material-symbols-outlined {
    font-variation-settings: 'FILL' 0, 'wght' 400, 'GRAD' 0, 'opsz' 48;
  }

  body.page-onboard {
    overflow-y: auto;
    background: var(--background-base);
  }

  .psm__progress-bar-overlay {
    &.background-color-ok {
      background-color: #21a221;
    }

    &.background-color-not-ok {
      background-color: rgb(var(--color-warn));
    }
  }

  @media (min-width: 750px) {
    .custom-modalbox-hop-calendar {
      width: 57%;
      height: 80%;
    }
  }

  @media (max-width: 600px) {
    mat-form-field {
      .mat-mdc-form-field-error-wrapper {
        position: static;
        margin-top: -22px;
      }
    }
  }

  .dialog-open-availability {
    width: 40%;
  }

  .dialog-open-wide {
    width: 100%;
    max-width: 64rem !important;

    @screen sm {
      width: 80%;
    }
  }

  .dialog-open-ai {
    @extend .dialog-open-wide;
    max-height: 90% !important;
  }


  @media (max-width: 920px) {
    .dialog-open-availability {
      width: 100%;
    }
  }

  .dialog-open-menu-action {
    width: 40%;
  }

  @media (max-width: 620px) {
    .cdk-overlay-pane {
      max-width: 100vw !important;
      //height: 100% !important; // this is important to avoid the dialog to be cut off on IOS, if we use 100vh https://webflow.com/made-in-webflow/website/fullscreen-modal
    }

    /*.mat-mdc-dialog-content {
      max-height: initial;
    }*/

    .mat-mdc-dialog-title {
      max-width: 75%;
    }
  }

  .mat-mdc-dialog-title {
    max-width: 85%;
  }

  @media (max-width: 920px) {
    .dialog-open-menu-action {
      width: 100%;
    }
  }

  .dialog-open-gdpr {
    width: 60%;
  }

  @media (max-width: 920px) {
    .dialog-open-gdpr {
      width: 100%;
    }
  }

  .dialog-open-overrides {
    width: 40%;
    height: 85%;
  }

  @media (max-width: 1130px) {
    .dialog-open-overrides {
      width: 85%;
    }
  }

  .dialog-open-connected-calendars {
    width: 45%;
  }

  @media (max-width: 1000px) {
    .dialog-open-connected-calendars {
      width: 75%;
    }
  }

  .ql-container {
    height: fit-content;
  }

  .ql-video {
    width: 100%;
    aspect-ratio: 16/9;
  }

  /*.ql-editor {

    // forced styles to display better some ql items
    a {
      color: revert;
      text-decoration: revert;
    }

    pre.ql-syntax {
      background-color: #23241f;
      color: #f8f8f2;
      overflow: visible;
    }

    font-size: 1.1rem;
    line-height: 1.85rem;
  }*/


  .fixed-submit-button {
    position: fixed;
    bottom: 50px;
    right: 24px;
    z-index: 100;
    @apply px-12 py-6 text-base;
  }

  #section3 {
    @apply my-16;
  }

  ckeditor {
    @apply md:max-w-5xl;
  }

  // div {
  //   box-sizing: border-box;
  // }

  .card-generic {
    @apply bg-foreground flex px-4 sm:px-8 py-4 border sm:py-8 m-auto rounded-lg w-full;
  }

  .form-field .mat-mdc-form-field-subscript-wrapper {
    display: none !important;
  }

  .dark {
    --mdc-filled-text-field-container-color: rgba(255, 255, 255, 0);
  }

  --mdc-filled-text-field-container-color: rgba(255, 255, 255, 0);

  .mat-mdc-raised-button.mat-primary {
    --mdc-protected-button-label-text-color: #fff;
  }

  /*
  .mat-mdc-fab.mat-primary, .mat-mdc-mini-fab.mat-primary {
    --mdc-fab-container-color: #ea3eb9;
    --mdc-fab-icon-color: white;
    --mat-mdc-fab-color: #fff;
  }*/
  .mat-mdc-raised-button .mat-primary {
    color: white !important;
  }

  markdown {
    @apply ck-content;
  }

  mat-sidenav-container,
  mat-sidenav-content {
    overflow: initial
  }
}